@charset "UTF-8";
/* 公共flex方法：调用方式 --> ;
先引入 @import '../common/global.scss';
再调用 @include flex-def(); */
/* 定义 */
/* 主轴居中 */
/* 主轴两端对齐 */
/* 主轴end对齐 */
/* 主轴start对齐 */
/* 侧轴居中 */
/* 侧轴start对齐 */
/* 侧轴底部对齐 */
/* 侧轴文本基线对齐 */
/* 侧轴上下对齐并铺满 */
/* 主轴从上到下 */
/* 主轴从下到上 */
/* 主轴从左到右 */
/* 主轴从右到左 */
/*  */
/* 是否允许子元素伸缩 */
/* 子元素的显示次序 */
/*  */
/* 旋转 角度(angle) */
/* 超出几行省略... */
.AuthError {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); }
  .AuthError .AuthErrorcore {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -50px;
    width: 200px;
    height: 120px;
    background: #fff;
    text-align: center;
    border-radius: 5px; }
    .AuthError .AuthErrorcore > span {
      display: block;
      font-size: 14px; }
      .AuthError .AuthErrorcore > span:nth-child(1) {
        margin-top: 30px; }
      .AuthError .AuthErrorcore > span:nth-child(2) {
        margin-top: 10px;
        display: inline-block;
        font-size: 12px;
        color: #2873fb;
        border: 1px solid #2873fb;
        border-radius: 30px;
        padding: 5px 20px;
        cursor: pointer; }

body, html {
  width: 100%;
  height: 100%; }

.AuthAddPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); }
  .AuthAddPage .AuthAddPageCore {
    width: 562px;
    height: 440px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -220px;
    margin-left: -281px; }
    .AuthAddPage .AuthAddPageCore .AuthAddPageCoreHeader {
      display: -webkit-box;
      /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
      display: -moz-box;
      /* 老版本语法: Firefox (buggy) */
      display: -ms-flexbox;
      /* 混合版本语法: IE 10 */
      display: -webkit-flex;
      /* 新版本语法: Chrome 21+ */
      display: flex;
      /* 新版本语法: Opera 12.1, Firefox 22+ */
      -webkit-box-pack: justify;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding: 0px 30px 0 14px;
      border-radius: 5px;
      border-bottom: 1px solid #2873fb; }
      .AuthAddPage .AuthAddPageCore .AuthAddPageCoreHeader > div {
        text-align: left; }
      .AuthAddPage .AuthAddPageCore .AuthAddPageCoreHeader h4 {
        font-size: 16px;
        color: #555;
        cursor: pointer; }
      .AuthAddPage .AuthAddPageCore .AuthAddPageCoreHeader p {
        padding-left: 16px;
        font-size: 12px;
        color: #999999; }
      .AuthAddPage .AuthAddPageCore .AuthAddPageCoreHeader .arrow_icon {
        position: relative;
        width: 12px;
        display: inline-block;
        vertical-align: top; }
        .AuthAddPage .AuthAddPageCore .AuthAddPageCoreHeader .arrow_icon::before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 7px;
          left: 0;
          height: 0;
          width: 0;
          border: 3px solid transparent;
          border-left: 6px solid #6798f9;
          vertical-align: middle; }
        .AuthAddPage .AuthAddPageCore .AuthAddPageCoreHeader .arrow_icon::after {
          content: "";
          display: inline-block;
          height: 0;
          width: 4px;
          box-shadow: 0 0 8px 1px #6798f9;
          position: absolute;
          left: 2px;
          top: 11px; }
    .AuthAddPage .AuthAddPageCore .AuthAddPageCoreContent {
      height: 261px;
      padding: 0 30px; }
      .AuthAddPage .AuthAddPageCore .AuthAddPageCoreContent h4 {
        padding: 0;
        margin-top: 20px;
        color: #555555;
        text-align: left;
        font-size: 14px; }
      .AuthAddPage .AuthAddPageCore .AuthAddPageCoreContent .AuthAddPageCoreContentMyPage {
        overflow: auto;
        height: 189px;
        text-align: left; }
        .AuthAddPage .AuthAddPageCore .AuthAddPageCoreContent .AuthAddPageCoreContentMyPage .oneself_mo2_select {
          margin: 9px 0; }
        .AuthAddPage .AuthAddPageCore .AuthAddPageCoreContent .AuthAddPageCoreContentMyPage p {
          margin: 0;
          padding: 0; }
        .AuthAddPage .AuthAddPageCore .AuthAddPageCoreContent .AuthAddPageCoreContentMyPage .name label {
          font-size: 14px;
          color: #555555; }
        .AuthAddPage .AuthAddPageCore .AuthAddPageCoreContent .AuthAddPageCoreContentMyPage .username {
          margin-left: 15px;
          font-size: 12px;
          color: #999999; }
    .AuthAddPage .AuthAddPageCore .footer {
      position: relative;
      height: 56px;
      text-align: right; }
      .AuthAddPage .AuthAddPageCore .footer div {
        padding-right: 30px; }
        .AuthAddPage .AuthAddPageCore .footer div span {
          display: inline-block;
          height: 28px;
          line-height: 28px;
          font-size: 12px;
          color: #ccc;
          border: 1px solid #ccc;
          border-radius: 30px;
          padding: 0 20px;
          cursor: pointer;
          margin-left: 10px; }
          .AuthAddPage .AuthAddPageCore .footer div span:nth-child(2) {
            color: #fff;
            border-color: #2873fb;
            background: #2873fb;
            box-shadow: 0 6px 25px 0 rgba(62, 129, 250, 0.4); }
            .AuthAddPage .AuthAddPageCore .footer div span:nth-child(2):hover {
              color: #fff;
              background-color: #3e81fa;
              box-shadow: 0 6px 25px 0 rgba(62, 129, 250, 0.4); }
          .AuthAddPage .AuthAddPageCore .footer div span.OneSelfMo_Nobtn {
            background: #ccc;
            box-shadow: none;
            border: 1px solid #ccc; }
            .AuthAddPage .AuthAddPageCore .footer div span.OneSelfMo_Nobtn:hover {
              background: #ccc;
              box-shadow: none; }

#root {
  width: 100%;
  height: 100%; }
  #root .App {
    width: 100%;
    height: 100%; }
    #root .App .AuthBox {
      width: 100%;
      height: 100%; }

/* loading */
.loader_wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.loader {
  color: #52acff;
  font-size: 40px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 25px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease; }

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.AuthorizedBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1); }
  .AuthorizedBox .AuthorizedBoxCore {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 732px;
    height: 474px;
    margin-left: -350px;
    margin-top: -237px;
    background: #fff;
    border-radius: 15px;
    padding: 0 18px; }
    .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreHeader {
      border-bottom: 1px solid #eeeeee;
      height: 53px;
      line-height: 53px;
      text-align: left; }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreHeader span {
        font-size: 14px;
        color: #6c6c70;
        margin-bottom: 4px; }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreHeader small {
        margin: 0 0 0 10px;
        font-size: 12px;
        color: #6e767c; }
    .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreInfo {
      display: -webkit-box;
      /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
      display: -moz-box;
      /* 老版本语法: Firefox (buggy) */
      display: -ms-flexbox;
      /* 混合版本语法: IE 10 */
      display: -webkit-flex;
      /* 新版本语法: Chrome 21+ */
      display: flex;
      /* 新版本语法: Opera 12.1, Firefox 22+ */
      -webkit-box-pack: center;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 23px 0; }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreInfo dl {
        display: -webkit-box;
        /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
        display: -moz-box;
        /* 老版本语法: Firefox (buggy) */
        display: -ms-flexbox;
        /* 混合版本语法: IE 10 */
        display: -webkit-flex;
        /* 新版本语法: Chrome 21+ */
        display: flex;
        /* 新版本语法: Opera 12.1, Firefox 22+ */
        -webkit-box-pack: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreInfo dl dt {
          float: left;
          position: relative; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreInfo dl dt .icon-facebook {
            position: absolute;
            bottom: -10px;
            right: -3px;
            color: #4c75cb;
            font-size: 18px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreInfo dl dt img {
            width: 48px;
            height: 48px;
            border-radius: 50%; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreInfo dl dd {
          margin-left: 10px;
          float: right;
          text-align: left; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreInfo dl dd span {
            display: block;
            font-size: 14px;
            color: #5e5e61; }
            .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreInfo dl dd span:nth-child(2) {
              color: #909599;
              font-size: 12px;
              margin-top: 3px; }
    .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent {
      height: 200px; }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeader {
        padding-left: 60px; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeader > span:nth-child(1) {
          width: 180px;
          height: 1px;
          background: #4c75cb;
          background: linear-gradient(to left, #80c9ff, white); }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeader > span:nth-child(2) {
          margin: 0 10px;
          color: #5e5e61;
          font-size: 12px; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeader > span:nth-child(3) {
          width: 180px;
          height: 1px;
          background: #4c75cb;
          background: linear-gradient(to right, #80c9ff, white); }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont {
        display: -webkit-box;
        /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
        display: -moz-box;
        /* 老版本语法: Firefox (buggy) */
        display: -ms-flexbox;
        /* 混合版本语法: IE 10 */
        display: -webkit-flex;
        /* 新版本语法: Chrome 21+ */
        display: flex;
        /* 新版本语法: Opera 12.1, Firefox 22+ */
        -webkit-box-pack: justify;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 90px; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div {
          margin-top: 50px;
          display: -webkit-box;
          /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
          display: -moz-box;
          /* 老版本语法: Firefox (buggy) */
          display: -ms-flexbox;
          /* 混合版本语法: IE 10 */
          display: -webkit-flex;
          /* 新版本语法: Chrome 21+ */
          display: flex;
          /* 新版本语法: Opera 12.1, Firefox 22+ */
          -webkit-box-align: center;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          padding: 0 9px;
          width: 150px;
          box-sizing: border-box;
          border: 1px solid #dddddd;
          border-bottom: 1px solid #80c9ff;
          height: 100px;
          text-align: center;
          margin-left: 23px;
          color: #5e5e61;
          position: relative; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div:nth-child(1) {
            margin-left: 0; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter .leftBorder::before, .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter .leftBorder::after {
            background: #80c9ff;
            content: '';
            position: absolute;
            left: -1px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter .leftBorder::before {
            width: 1px;
            height: 0px;
            bottom: 0;
            transition: height .3s linear; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter .leftBorder::after {
            width: 0px;
            height: 1px;
            top: -1px;
            transition: width .3s linear .3s; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter .rightBorder::before, .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter .rightBorder::after {
            background: #80c9ff;
            content: '';
            position: absolute;
            right: -1px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter .rightBorder::before {
            width: 1px;
            height: 0px;
            bottom: 0;
            transition: height .3s linear; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter .rightBorder::after {
            width: 0px;
            height: 1px;
            top: -1px;
            transition: width .3s linear .3s; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter:hover .leftBorder::before, .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter:hover .rightBorder::before {
            height: 99px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter:hover .leftBorder::after, .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxEnter:hover .rightBorder::after {
            width: 30px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver .leftBorder::before, .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver .leftBorder::after {
            background: #80c9ff;
            content: '';
            position: absolute;
            left: -1px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver .leftBorder::before {
            width: 1px;
            height: 0px;
            bottom: 0;
            transition: height .3s linear .3s; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver .leftBorder::after {
            width: 0px;
            height: 1px;
            top: -1px;
            transition: width .3s linear; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver .rightBorder::before, .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver .rightBorder::after {
            background: #80c9ff;
            content: '';
            position: absolute;
            right: -1px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver .rightBorder::before {
            width: 1px;
            height: 0px;
            bottom: 0;
            transition: height .3s linear  .3s; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver .rightBorder::after {
            width: 0px;
            height: 1px;
            top: -1px;
            transition: width .3s linear; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver:hover .leftBorder::before, .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver:hover .rightBorder::before {
            height: 99px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver:hover .leftBorder::after, .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div.boxleaver:hover .rightBorder::after {
            width: 30px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentHeaderCont > div font {
            position: absolute;
            top: -12px;
            background: #fff;
            display: inline-block;
            width: 90px;
            left: 50%;
            text-align: center;
            margin-left: -45px;
            color: #48b1ff; }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthSuccess {
        margin-top: 40px;
        text-align: center; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthSuccess i {
          color: #2e70fb;
          font-size: 30px;
          margin-left: -12px; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthSuccess span {
          display: block;
          color: #5e5e61; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthSuccess span:nth-child(2) {
            font-size: 14px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthSuccess span:nth-child(3) {
            font-size: 12px;
            margin-top: 20px; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthSuccess .gradient {
          margin-top: 30px;
          height: 1px;
          width: 100%;
          background: linear-gradient(to right, white, #bce2ff, #48b1ff, #bce2ff, white); }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthError {
        margin-top: 20px;
        text-align: center; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthError i {
          display: inline-block;
          background: #2e70fb;
          font-size: 30px;
          margin-left: -12px;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          color: #fff;
          margin: 10px 0; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthError .AuthErrorTooltip {
          text-align: center;
          color: #999;
          font-size: 12px; }
          .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthError .AuthErrorTooltip > span {
            display: block;
            text-align: left;
            padding-left: 100px; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthError span {
          display: block; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthError div {
          text-align: left;
          font-size: 12px;
          color: #5e5e61;
          padding: 0 100px; }
        .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContent .AuthorizedBoxCoreContentAuthError .gradient {
          margin-top: 30px;
          height: 1px;
          width: 100%;
          background: linear-gradient(to right, white, #bce2ff, #48b1ff, #bce2ff, white); }
    .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContentFooter {
      display: -webkit-box;
      /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
      display: -moz-box;
      /* 老版本语法: Firefox (buggy) */
      display: -ms-flexbox;
      /* 混合版本语法: IE 10 */
      display: -webkit-flex;
      /* 新版本语法: Chrome 21+ */
      display: flex;
      /* 新版本语法: Opera 12.1, Firefox 22+ */
      -webkit-box-align: center;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column; }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContentFooter .btn-medium {
        padding: 0 50px;
        margin-top: 20px; }
      .AuthorizedBox .AuthorizedBoxCore .AuthorizedBoxCoreContentFooter span {
        cursor: pointer;
        display: block;
        height: 30px;
        font-size: 14px;
        line-height: 29px;
        border-radius: 16px;
        color: #fff;
        background: #2873fb; }

@keyframes myfirst {
  from {
    border-color: #fff; }
  to {
    border-color: #80c9ff; } }

@-moz-keyframes myfirst /* Firefox */ {
  from {
    border-color: #fff; }
  to {
    border-color: #80c9ff; } }

@-webkit-keyframes myfirst /* Safari 和 Chrome */ {
  from {
    border-color: #fff; }
  to {
    border-color: #80c9ff; } }

@-o-keyframes myfirst /* Opera */ {
  from {
    border-color: #fff; }
  to {
    border-color: #80c9ff; } }

@keyframes verticalBorderAnimation {
  0% {
    height: 0; }
  100% {
    height: 99px; } }

@keyframes horizontalBorderAnimation {
  0% {
    width: 0; }
  100% {
    width: 30px; } }

@keyframes showBorderAnimation {
  0% {
    z-index: 1; }
  99% {
    z-index: 1; }
  100% {
    z-index: 0; } }

.authAddPageBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); }
  .authAddPageBox .authAddPageError {
    width: 638px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: -319px;
    background: #fff;
    border-radius: 10px;
    text-align: center; }
    .authAddPageBox .authAddPageError .authAddPageErrorheader {
      height: 54px;
      line-height: 54px;
      text-align: left;
      border-bottom: 1px solid #276cf6;
      color: #555555;
      font-size: 16px;
      padding-left: 30px; }
    .authAddPageBox .authAddPageError .authAddPageErrormain {
      margin: 40px 0; }
      .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfo dl {
        display: -webkit-box;
        /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
        display: -moz-box;
        /* 老版本语法: Firefox (buggy) */
        display: -ms-flexbox;
        /* 混合版本语法: IE 10 */
        display: -webkit-flex;
        /* 新版本语法: Chrome 21+ */
        display: flex;
        /* 新版本语法: Opera 12.1, Firefox 22+ */
        -webkit-box-pack: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center; }
        .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfo dl dt img {
          width: 50px;
          height: 50px;
          border-radius: 50%; }
        .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfo dl dd {
          margin-left: 10px;
          text-align: left; }
          .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfo dl dd span {
            display: block; }
            .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfo dl dd span:nth-child(1) {
              margin-top: 10px;
              color: #5e5e61;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              /*! autoprefixer: off */
              -webkit-box-orient: vertical;
              word-break: break-all; }
            .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfo dl dd span:nth-child(2) {
              color: #909599;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              /*! autoprefixer: off */
              -webkit-box-orient: vertical;
              word-break: break-all; }
      .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfos {
        display: -webkit-box;
        /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
        display: -moz-box;
        /* 老版本语法: Firefox (buggy) */
        display: -ms-flexbox;
        /* 混合版本语法: IE 10 */
        display: -webkit-flex;
        /* 新版本语法: Chrome 21+ */
        display: flex;
        /* 新版本语法: Opera 12.1, Firefox 22+ */
        -webkit-box-pack: start;
        -moz-justify-content: start;
        -webkit-justify-content: start;
        justify-content: start;
        padding: 0 100px 0 145px;
        flex-wrap: wrap; }
        .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfos dl {
          width: 50%;
          display: -webkit-box;
          /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
          display: -moz-box;
          /* 老版本语法: Firefox (buggy) */
          display: -ms-flexbox;
          /* 混合版本语法: IE 10 */
          display: -webkit-flex;
          /* 新版本语法: Chrome 21+ */
          display: flex;
          /* 新版本语法: Opera 12.1, Firefox 22+ */
          padding-right: 10px;
          box-sizing: border-box; }
          .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfos dl dt img {
            width: 50px;
            height: 50px;
            border-radius: 50%; }
          .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfos dl dd {
            margin-left: 10px;
            text-align: left; }
            .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfos dl dd span {
              display: block; }
              .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfos dl dd span:nth-child(1) {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                /*! autoprefixer: off */
                -webkit-box-orient: vertical;
                word-break: break-all;
                margin-top: 10px;
                color: #5e5e61;
                font-size: 14px; }
              .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainInfos dl dd span:nth-child(2) {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                /*! autoprefixer: off */
                -webkit-box-orient: vertical;
                word-break: break-all;
                color: #909599;
                font-size: 12px; }
      .authAddPageBox .authAddPageError .authAddPageErrormain .iconfont {
        display: inline-block;
        background: #2e70fb;
        font-size: 30px;
        margin-left: -12px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        color: #fff;
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: 5px; }
      .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainTitle {
        color: #5e5e61;
        font-size: 14px;
        margin-bottom: 20px; }
      .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainTooltip {
        color: #999;
        font-size: 12px; }
        .authAddPageBox .authAddPageError .authAddPageErrormain .authAddPageErrormainTooltip > span {
          display: block;
          text-align: left;
          padding-left: 100px; }
    .authAddPageBox .authAddPageError .authAddPageErrormainfooter {
      text-align: center;
      margin-top: 20px; }
      .authAddPageBox .authAddPageError .authAddPageErrormainfooter span {
        display: inline-block;
        height: 38px;
        line-height: 38px;
        font-size: 12px;
        background: #2873fb;
        color: #fff;
        border-radius: 30px;
        padding: 0 30px;
        cursor: pointer;
        box-shadow: 0 6px 25px 0 rgba(62, 129, 250, 0.4); }
        .authAddPageBox .authAddPageError .authAddPageErrormainfooter span:hover {
          color: #fff;
          background-color: #3e81fa;
          box-shadow: 0 6px 25px 0 rgba(62, 129, 250, 0.4); }
