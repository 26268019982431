/* 公共flex方法：调用方式 --> ;
先引入 @import '../common/global.scss';
再调用 @include flex-def(); */

// 主题蓝色
$themeBlue: #276cf6;

// 各平台背景颜色
$facebookBgColor: #1877f2;
$twitterBgColor: #4fa0ec;
$instagramBgColor: #ba6183;
$linkedinBgColor: #0b66c1;
$youtubeBgColor: #e83324;

/* 定义 */

@mixin flex-def {
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: flex; /* 新版本语法: Opera 12.1, Firefox 22+ */
}

/* 主轴居中 */
@mixin flex-zCenter {
    -webkit-box-pack: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
}

/* 主轴两端对齐 */
@mixin flex-zBetween {
    -webkit-box-pack: justify;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

/* 主轴end对齐 */
@mixin flex-zEnd {
    -webkit-box-pack: end;
    -moz-justify-content: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

/* 主轴start对齐 */
@mixin flex-zStart {
    -webkit-box-pack: start;
    -moz-justify-content: start;
    -webkit-justify-content: start;
    justify-content: start;
}

/* 侧轴居中 */
@mixin flex-cCenter {
    -webkit-box-align: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
}

/* 侧轴start对齐 */
@mixin flex-cStart {
    -webkit-box-align: start;
    -moz-align-items: start;
    -webkit-align-items: start;
    align-items: start;
}

/* 侧轴底部对齐 */
@mixin flex-cEnd {
    -webkit-box-align: end;
    -moz-align-items: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

/* 侧轴文本基线对齐 */
@mixin flex-cBaseline {
    -webkit-box-align: baseline;
    -moz-align-items: baseline;
    -webkit-align-items: baseline;
    align-items: baseline;
}

/* 侧轴上下对齐并铺满 */
@mixin flex-cStretch {
    -webkit-box-align: stretch;
    -moz-align-items: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;
}

/* 主轴从上到下 */
@mixin flex-zTopBottom {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

/* 主轴从下到上 */
@mixin flex-zBottomTop {
    -webkit-box-pack: end;
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

/* 主轴从左到右 */
@mixin flex-zLeftRight {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
}

/* 主轴从右到左 */
@mixin flex-zRightLeft {
    -webkit-box-pack: end;
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

/*  */
@mixin flex-sEvenly {
    -webkit-box-pack: space-evenly;
    -moz-justify-content: space-evenly;
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
}

/* 是否允许子元素伸缩 */
@mixin flex-item {
    -webkit-box-flex: 1.0;
    -moz-flex-grow: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
}
/* 子元素的显示次序 */
@mixin flex-order{
    -webkit-box-ordinal-group: 1;
    -moz-order: 1;
    -webkit-order: 1;
    order: 1;
}
/*  */
@mixin flex-zWrap {
    -webkit-box-pack: wrap;
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
@mixin align-content($value:center) {
    -moz-align-content:$value;
    -webkit-align-content:$value;
    align-content:$value;
}

/* 旋转 角度(angle) */
@mixin transform-rotate($angle:180deg) {
    transform:rotate($angle);
    -ms-transform:rotate($angle); /* Internet Explorer */
    -moz-transform:rotate($angle); /* Firefox */
    -webkit-transform:rotate($angle); /* Safari 和 Chrome */
    -o-transform:rotate($angle); /* Opera */
}
/* 超出几行省略... */
@mixin ellipsis($webkit-line-clamp:3) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $webkit-line-clamp;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    @if $webkit-line-clamp == 1 {
        word-break: break-all;
    }
 }
// font-size
@mixin fontSize($fontSize, $height: $fontSize) {
    font-size: $fontSize;
    height: $height;
    line-height: $height;
}

// 图片变灰
@mixin imgGray {
    -webkit-filter: grayscale(1); /* Webkit */
    filter: gray; /* IE6-9 */  
    filter: grayscale(1); /* W3C */
}

// 取消图片变灰
@mixin cancelImgGray {
    -webkit-filter: none; /* Webkit */
    filter: none; /* IE6-9 */  
    filter: none; /* W3C */
}

// $hShadow：水平阴影的位置, $vShadow：垂直阴影的位置, $blur：模糊距离
// $spread：阴影的尺寸, $color：阴影的颜色
@mixin boxShadow($hShadow, $vShadow, $blur, $spread, $color, $inset:'') {    
    $params: $hShadow $vShadow $blur $spread $color;
    @if $inset == 'inset' {
        $params: $params inset;
    }
    -moz-box-shadow: $params;
    -webkit-box-shadow: $params;
    box-shadow: $params;
}

@mixin placeholder($fontsize: 14px, $color: #ccc) {
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: $fontsize;
        color: $color;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        font-size: $fontsize;
        color: $color;

    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        font-size: $fontsize;
        color: $color;
    }
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        font-size: $fontsize;
        color: $color;
    }
}

@mixin scrollBar() {
    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #eee;
    }
    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
    }
}

// 背景渐变，$direction：left 从左到右，right 从右到左
@mixin bgGradient($direction, $colorStop1, $colorStop2) {
    $d: left;
    @if $direction == left {
        $d: right;
    }
    @if $direction == top {
        $d: bottom;
    }
    @if $direction == bottom {
        $d: top;
    }
    background: -webkit-linear-gradient($direction, $colorStop1 , $colorStop2); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient($direction, $colorStop1, $colorStop2); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient($direction, $colorStop1, $colorStop2); /* Firefox 3.6 - 15 */
    background: linear-gradient(to $d, $colorStop1 , $colorStop2); /* 标准的语法 */
}

// $name：keyframe名称，$duration：动画花费的时间，$timingFunction：动画的速度曲线
// $delay：动画开始之前的延迟，$iterationCount：动画播放的次数，$direction：是否应该轮流反向播放动画
@mixin animation($name, $duration, $timingFunction: linear, $delay: 0s, $iterationCount: 1, $direction: normal) {
    animation: $name $duration $timingFunction $delay $iterationCount $direction;
    /* Firefox: */
    -moz-animation: $name $duration $timingFunction $delay $iterationCount $direction;
    /* Safari 和 Chrome: */
    -webkit-animation: $name $duration $timingFunction $delay $iterationCount $direction;
    /* Opera: */
    -o-animation: $name $duration $timingFunction $delay $iterationCount $direction;
}
